@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

*,
** {
  box-sizing: border-box;
}

body {
  position: relative;
  font-family: "Roboto";
  display: flex;
  align-items: stretch;
  padding: 0;
  margin: 0;
}

#root {
  position: absolute;
  left: 0;
  top: 0;
  width: 211mm;
  height: 297mm;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

h2 {
  font-size: 1.17em;
}

h3 {
  font-size: 1em;
}
