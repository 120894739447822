.p-skill {
  font-size: 14px;
}

.p-skill::after {
  content: ", ";
}

.p-skill:last-child::after {
  content: "";
}
