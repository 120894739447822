a::after {
  content: "";
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0.125rem 0 0 0.125rem;
  background-size: 0.5rem 0.5rem;
  pointer-events: none;
  color: inherit;
  background-image: url("./link.svg");
  vertical-align: top;
}

a {
  text-decoration: none;
}
